<template>
    <div class="page">
        <div class="page-header">
            <el-page-header @back="$router.go(-1)" content="专家抽取"/>
        </div>
        <el-row class="page-main" :gutter="20" style="margin-top: 20px">
            <el-col :xs="24" :sm="12" :md="7">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>项目信息</span>
                    </div>
                    <div style="height:100%;overflow-y: auto;">
                        <el-form ref="projectForm" :model="form" :rules="rules" label-width="80px">
                            <el-form-item label="项目名称" prop="project.name">
                                <el-input v-model="form.project.name"></el-input>
                            </el-form-item>
                            <el-form-item label="项目代码">
                                <el-input v-model="form.project.code"></el-input>
                            </el-form-item>
                            <el-form-item label="评审时间" prop="project.startTime">
                                <el-date-picker style="width: 100%" v-model="form.project.startTime" type="date"
                                                placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="联系人" prop="project.contactName">
                                <el-input v-model="form.project.contactName" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="联系方式" prop="project.contactPhone">
                                <el-input v-model="form.project.contactPhone" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="备注">
                                <el-input type="textarea" v-model="form.project.remarks"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="13">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>抽取条件设置</span>
                    </div>
                    <div style="height:100%;overflow-y: auto;">
                        <el-form :model="form" :rules="rules" ref="chooseForm" label-width="80px" class="demo-form">
                            <el-row>
                                <el-col :xs="24" :sm="12" :md="12" >
                                    <el-form-item label="抽取数量" prop="chooseCount">
                                        <el-input v-model="form.chooseCount" style="width:100%">
                                            <template slot="append">人</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12" :md="12">
                                    <el-form-item>
                                        参与抽取专家 <span style="font-size:150%;color: red">{{ form.expertCount }}</span> 人
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="分类" prop="oriente">
                                <selectExpertCategory v-model="form.category" size="default" :collapse="false"
                                                      @input="getExpertCount"/>
                            </el-form-item>
                            <el-form-item label="科室" prop="department">
                                <selectExpertDepartment v-model="form.department" size="default" :collapse="false"
                                                        @input="getExpertCount"/>
                            </el-form-item>
                            <el-form-item label="专业" prop="major">
                                <selectExpertMajor v-model="form.major" size="default" :collapse="false"
                                                   @input="getExpertCount"/>
                            </el-form-item>
                            <el-form-item label="职称" prop="title">
                                <selectExpertTitle v-model="form.title" size="default" :collapse="false"
                                                   @input="getExpertCount"/>
                            </el-form-item>
                            <el-form-item label="固定专家" prop="oriente">
                                <selectExpert v-model="form.preferred" size="default" :collapse="false"
                                              @input="getExpertCount"/>
                            </el-form-item>
                            <el-form-item label="回避专家">
                                <selectExpert v-model="form.avoided" size="default" :collapse="false"
                                              @input="getExpertCount"/>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="handelChoose('reset')" :loading="loading" size="small">专家抽取
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="12" :md="4"  class="page-header hidden-sm-and-down">
                <el-steps :active="active" direction="vertical">
                    <el-step title="登记项目" description="登记项目信息"></el-step>
                    <el-step title="设置抽取条件" description="设置抽取条件"></el-step>
                    <el-step title="预览结果" description="预览抽取结果"></el-step>
                    <el-step title="抽取完成" description="抽取完成"></el-step>
                    <el-step title="打印报告" description="打印抽取记录单"></el-step>
                </el-steps>
            </el-col>
        </el-row>
        <el-dialog title="抽取" :visible.sync="dialogVisible" width="70%" >
            <div style="height: 60vh;overflow-y: auto;padding: 0 20px">
                <el-divider content-position="left">随机专家</el-divider>
                <el-row>
                    <chou-qu  :slots="cqList" ref="chouqu" @handleShow="handleShow" @handleExpertDelete="handleExpertDelete"></chou-qu>
                </el-row>
<!--                <el-row :gutter="20" style="width: 100%;" v-else>-->
<!--                    <el-col :span="8" v-for="(item, index) in this.form.result.random" :key="index"-->
<!--                            style="position: relative;">-->
<!--                        <el-card shadow="hover" style="margin-bottom: 10px">-->
<!--                            <div class="del">-->
<!--                                <i class="el-icon-circle-close" @click="handleExpertDelete(index, 'random')"></i>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <div class="expert-card">-->
<!--                                    <el-image class="img" :src="item.image">-->
<!--                                        <div slot="error" class="image-slot">-->
<!--                                            <img src="../../assets/none.png" class="img">-->
<!--                                        </div>-->
<!--                                    </el-image>-->
<!--                                    <div class="content">-->
<!--                                        <div>姓名：{{ item.name }}</div>-->
<!--                                        <div>专业：{{ item.major }}</div>-->
<!--                                        <div>职称：{{ item.title }}</div>-->
<!--                                        <div>学历：{{ item.education }}</div>-->
<!--                                        <div>联系电话：{{ item.contact }}</div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </el-card>-->
<!--                    </el-col>-->
<!--                </el-row>-->
                <el-row v-if="this.form.preferred.length > 0">
                    <el-divider content-position="left">固定专家</el-divider>
                    <el-col :xs="24" :sm="12" :md="8" v-for="(item, index) in this.form.preferred" :key="index"
                            style="position: relative">
                        <el-card shadow="hover" style="margin: 10px">
                            <div class="del" style="right: 15px;top: 15px">
                                <i class="el-icon-circle-close" @click="handleExpertDelete(index, 'preferred')"></i>
                            </div>
                            <div class="expert-card">
                                <el-image class="img" :src="item.image">
                                    <div slot="error" class="image-slot">
                                        <img src="../../assets/none.png" class="img">
                                    </div>
                                </el-image>
                                <div class="content">
                                    <div>姓名：{{ item.name }}</div>
                                    <div>专业：{{ item.major }}</div>
                                    <div>职称：{{ item.title }}</div>
                                    <div>学历：{{ item.education }}</div>
                                    <div>联系电话：{{ item.contact }}</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
            <span slot="footer" class="dialog-footer">
                <div style="float:left">
                    <el-button @click="handelChoose('reset')" size="small">全部重抽</el-button>
                    <el-button @click="handelChoose" size="small">补充抽取</el-button>
                </div>
                <div class="btn">
                    <el-button @click="handelClean" size="small">取 消</el-button>
                    <el-button type="primary" @click="handelSubmit" size="small">确定完成</el-button>
                </div>

            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    import ChouQu from "./widgets/chouqu";
    export default {
        components: {ChouQu},
        data() {
            var validateChooseCount = (rule, value, callback) => {
                if (!/^\d+$/.test(value)) {
                    callback(new Error('数量只能为整数'));
                }
                let count = parseInt(value)
                if (count > parseInt(this.form.expertCount) || count < 0) {
                    callback(new Error(`请输入 0 - ${this.form.expertCount} 之间的数字`));
                } else {
                    callback();
                }
            };
            return {
                dialogVisible: false,
                loading: false,
                active: 0,
                form: {
                    project: {
                        contactName: this.$store.getters.user?.name,
                        contactPhone: this.$store.getters.user.data?.phone
                    },
                    chooseCount: 3,
                    expertCount: 0,
                    category: [],
                    department: [],
                    major: [],
                    title: [],
                    education: [],
                    preferred: [],
                    avoided: [],
                    result: {
                        random: []
                    },
                },
                cqList:[],
                show: true,
                rules: {
                    project: {
                        name: [
                            {required: true, message: '此项为必填项', trigger: 'blur'}
                        ],
                        startTime: [
                            {required: true, message: '此项为必填项', trigger: 'blur'}
                        ],
                        contactName: [
                            {required: true, message: '此项为必填项', trigger: 'blur'}
                        ],
                        contactPhone: [
                            {required: true, message: '此项为必填项', trigger: 'blur'}
                        ],
                    },
                    chooseCount: [
                        {required: true, message: '请输入抽取数量', trigger: 'blur'},
                        {validator: validateChooseCount, trigger: 'blur'}
                    ]
                },
            };
        },
        computed: {
            ...mapState(["user"]),
        },
        methods: {
            handleShow(){
                this.show = false
            },
            handelClean() {
                this.dialogVisible = false
                this.active = 2
            },
            handleExpertDelete(index, type) {
                if (type == "random") {
                    this.form.result.random.splice(index, 1);
                } else {
                    this.form.preferred.splice(index, 1);
                }
            },
            async validateForms() {
                try {
                    await this.$refs['projectForm'].validate();
                    await this.$refs['chooseForm'].validate();
                    // 执行其他逻辑
                    return true;
                } catch (error) {
                    return false;
                }
            },
            async handelChoose(reset) {
                if (!await this.validateForms()) return false
                if (reset == 'reset') this.form.result.random = []
                this.loading = true
                this.active = 3
                var that = this
                this.$http.post("/choose/form", this.form)
                    .then(resp => {
                        if (resp.data.code != 200) {
                            this.$message.error(resp.data.msg);
                        } else {
                            this.form.result.random = resp.data.data;
                            that.cqList = []
                            resp.data.data.forEach(function (item) {
                                that.cqList.push({items:[item,item,item,item,item]})
                            })
                            this.dialogVisible = true
                            setTimeout(() => {
                                this.$refs.chouqu.init(reset);
                            },50)

                        }
                    }).catch(err => {
                    this.$message.error(err.message);
                }).finally(() => {
                    this.loading = false
                })
            },
            handelSubmit() {
                this.$http.post("/choose/form_done", this.form)
                    .then(resp => {
                        this.$message[resp.data.status](resp.data.msg);
                        if (resp.data.status == 'success') {
                            this.active = 4

                            this.$router.push(`/main/choose/chlog/${resp.data.data.id}`)
                        }
                    }).catch(err => {
                    this.$message.error(err.message);
                }).finally(() => {
                    this.loading = false;
                });
            },
            getExpertCount() {
                this.$http.post("/choose/counts", this.form)
                    .then(resp => {
                        this.form.expertCount = resp.data.count
                    }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        },
        watch: {
            'form.project': {
                deep: true,
                handler(newValue) {
                    var fieldList = ["name", "startTime", "contactName", "contactPhone"]
                    var flag = 0
                    fieldList.forEach(function (field) {
                        if (!newValue[field]) {
                            flag = 1
                        }
                    })
                    if (flag == 0) {
                        this.active = 2
                    }
                }
            },
            user: {
                deep: true,
                handler(newValue) {
                    if (!this.form.project.contactName) {
                        this.form.project.contactName = newValue?.name
                        this.form.project.contactPhone = newValue.data?.phone
                    }
                }
            }
        },
        mounted() {
            this.getExpertCount()
        }
    }
</script>


<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;

        .page-main {
            flex: 1;
        }

        .el-col {
            height: 100%;
        }

        ::v-deep .el-card {
            height: 100%;
        }

    }

    ::v-deep .el-dialog__body {
        padding: 0;
    }

    .expert-card {
        display: flex;
        cursor: pointer;
        align-items: center;

        .img {
            width: 100px;
            height: 140px;
            text-align: center;
            border-radius: 3px;
        }

        .content {
            flex: 1;
            padding: 0 10px;
            overflow: hidden;
            /* 超出部分隐藏 */
            /*text-overflow: ellipsis;*/
            div{
                padding: 5px 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

    }

    .del {
        position: absolute;
        top: 5px;
        right: 15px;
        cursor: pointer;
    }
    @media (max-width: 767px) {
        .el-col-xs-24 {
            height: inherit !important;
            padding-bottom: 20px;
        }
        .btn{
            display: inline-block;
            padding-top: 10px;
        }

    }
    @media (max-width: 992px) {
        ::v-deep .el-dialog{
            width: 96% !important;
            /*margin: 0 10px;*/
            margin-top: 5vh !important;

        }
    }

    @media (max-width: 1200px) {
        ::v-deep .el-dialog{
            width: 90% !important;
            /*margin: 0 10px;*/
            /*margin-top: 5vh !important;*/

        }
    }

</style>

