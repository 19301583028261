<template>
    <div class="slot-machine">
        <el-col v-for="(slot, index) in slots" :key="index" :xs="24" :sm="12" :md="8">
            <div class="slot" ref="slots">
                <div class="slot__window">
                    <div class="slot__wrap">
                        <div class="slot__item" v-for="(opt, idx) in slot.items" :key="`${index}_${idx}`">
                            <el-card shadow="hover" style="margin: 10px" class="card-del" >
                                <div class="del">
                                    <i class="el-icon-circle-close" @click="handleExpertDelete(index, 'random')"></i>
                                </div>
                                <div class="expert-card">
                                    <el-image class="img" :src="opt.image">
                                        <div slot="error" class="image-slot">
                                            <img src="../../../assets/none.png" class="img">
                                        </div>
                                    </el-image>
                                    <div class="content">
                                        <div>姓名：{{ opt.name }}</div>
                                        <div>专业：{{ opt.major }}</div>
                                        <div>职称：{{ opt.title }}</div>
                                        <div>学历：{{ opt.education }}</div>
                                        <div>联系电话：{{ opt.contact }}</div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
            </div>
        </el-col>
    </div>
</template>


<script>
    const next = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame || function (cb) {
            window.setTimeout(cb, 1000 / 60)
        }
    export default {
        props: {
            slots: {
                type: Array,
                default: function () {
                    return []; // 返回一个空对象作为默认值
                }
            },
        },
        data() {
            return {
                // slots: [],
                prizeNum: 0,
                opts: null,
                startedAt: null,
                ylTable :[],
            }
        },
        mounted() {

        },
        methods: {
            handleExpertDelete(index, type) {
                this.$emit('handleExpertDelete', index, type);
                this.slots.splice(index, 1);
                this.ylTable =  this.slots
                this.start(1)
            },
            init(type) {
                var that = this
                if(type=="reset"){
                    this.ylTable = []
                }
                setTimeout(() => {
                    that.start(0)
                }, 20)
            },
            start(type) {
                if (type == 1) {
                    return
                }
                // if (this.opts) {
                //     // 增加动画过程中，再次点击开始，立即结束动画，且置为对应中将位置
                //     this.opts.forEach(opt => {
                //         opt.isFinished = true
                //         const pos = -opt.finalPos
                //         opt.el.style.transform = "translateY(" + pos + "px)"
                //     })
                //     return
                // }
                var that = this;
                this.opts = this.slots.map((data, i) => {
                    const slot = this.$refs.slots[i]; // map(function(){})利用map便利slots的每一个选项组,最终得到return的返回值
                    const itemHeight = document.getElementsByClassName('slot__item')[0].offsetHeight
                    const choice = Math.floor(Math.random() * data.items.length); // 随机生成一个[0,data.items.length]的整数(floor向下取整)
                    const opts = {
                        el: slot.querySelector(".slot__wrap"), //指向奖项元素的父级
                        finalPos: choice * itemHeight, // itemHeight 为每一个奖品滚动标签的高度
                        startOffset: 1000 + Math.random() * 500 + i * 500, // 影响转的圈数
                        height: data.items.length * itemHeight,
                        duration: 3000 + i * 700, // milliseconds
                        isFinished: false,
                    };
                    var flag = 0;
                    that.ylTable.forEach(function (item) {
                        if(item.items[0].id== data.items[0].id){
                            flag = 1
                        }
                    })
                    if(flag != 0){
                        opts.isFinished = true
                    }
                    return opts

                })

                if (type == 0) {
                    next(this.animate) // 开启动画
                }
            },
            animate(timestamp) {
                if (!this.opts) return
                // timestamp当前的方法持续的毫秒数
                if (this.startedAt == null) {
                    this.startedAt = timestamp // 动画初始时间
                }
                const timeDiff = timestamp - this.startedAt; //动画持续的时间
                this.opts.forEach((opt) => {
                    if (opt.isFinished) {
                        return
                    }
                    const timeRemaining = Math.max(opt.duration - timeDiff, 0); // 总的持续时间 - 动画持续时间 = 剩下的时间,0表示结束
                    const power = 3
                    const offset =
                        (Math.pow(timeRemaining, power) / Math.pow(opt.duration, power)) *
                        opt.startOffset; // Math.pow(timeRemaining, power)表示: timeRemaining 的3 次幂; // negative, such that slots move from top to bottom
                    const pos = -1 * Math.floor((offset + opt.finalPos) % opt.height)
                    opt.el.style.transform = "translateY(" + pos + "px)"
                    if (timeDiff > opt.duration) {
                        console.log('finished', opt, pos, opt.finalPost)
                        opt.isFinished = true
                    }
                })
                if (this.opts.every((o) => o.isFinished)) {
                    this.opts = null
                    this.ylTable = this.slots
                    this.startedAt = null
                } else {
                    next(this.animate)
                }
            },
        },
    };
</script>

<style scoped>
    .slot-machine {
        /*display: flex;*/
        /*justify-content: space-around;*/
        /*border: 1px solid #DCDFE6;*/
        width: 100%;
    }

    .slot {
        /*float: left;*/
        /*margin: 0.4em;*/
        /*width: 33.333%;*/
    }

    .slot__window {
        /*background-color: #8c939d;*/
        width: 100%;
        height: 235px;
        overflow: hidden;
    }

    .slot__item {
        padding: 20px 0;
        width: 100%;
        height: 200px;
    }

    .expert-card {
        display: flex;
        cursor: pointer;
        align-items: center;
    }

    .card-del {
        position: relative;
    }

    .img {
        width: 100px;
        height: 140px;
        text-align: center;
        border-radius: 3px;
    }

    .content {
        flex: 1;
        padding: 0 10px;
        overflow: hidden;
        /* 超出部分隐藏 */

    }

    .content div {
        padding: 5px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .del {
        position: absolute;
        top: 5px;
        right: 15px;
        cursor: pointer;
    }

</style>
